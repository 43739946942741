
































































































































































































































































































import DialogMixins from '@/mixins/DialogMixins';
import WidgetMixins from '@/mixins/WidgetMixins';
import { loadView, loadWidget, truncateContent } from '@/utils/helpers';
import Component, { mixins } from 'vue-class-component';
import { Getter, namespace } from 'vuex-class';
import moment from 'moment-timezone';
import {
  IAPIKeys,
  IContact,
  IGroup,
  IEventActivity,
  ISenderID,
  IUser,
  ITickets,
} from '@/types/types';
import { Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { numeric, required, min, max } from 'vee-validate/dist/rules';
import BuyTicketBottomSheet from './dialogs/BuyTicketBottomSheet.vue';

const authModule = namespace('auth');
const apiKeyModule = namespace('apiKeys');
const smsModule = namespace('sms');
const eventsModule = namespace('events');
const groupModule = namespace('group');
const contactModule = namespace('contact');
const usersModule = namespace('users');
extend('required', {
  ...required,
  message: 'Field is required',
});
extend('numeric', {
  ...numeric,
  message: 'Invalid phone number provided',
});
extend('min', {
  ...min,
  message: `Invalid phone number provided`,
});
extend('max', {
  ...max,
  message: `Maximum phone number must be 13`,
});

@Component({
  name: 'Dashboard',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    AddSMSSenderID: loadWidget(`widgets/AddSenderID`),
    ValidationProvider,
    ValidationObserver,
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
    CreateEventDialog: loadView(`pages/events/dialogs/CreateEventDialog`),
    DeleteDialog: loadWidget('widgets/DeleteDialog'),
    BuyTicketBottomSheet: loadView(`pages/events/dialogs/BuyTicketBottomSheet`),
  },
  filters: {
    timestamp(value: string, format?: string) {
      return moment(value).format(format ?? 'Do MMMM, YYYY');
    },
    truncateContent,
  },
})
export default class Dashboard extends mixins(WidgetMixins, DialogMixins) {
  timestamp(arg0: string) {
    throw new Error('Method not implemented.');
  }
  vRipple(vRipple: any) {
    throw new Error('Method not implemented.');
  }
  truncateContent(arg0: number) {
    throw new Error('Method not implemented.');
  }
  @Getter('getCreditBalance') creditBalance!: number;
  @Getter('getResetFormState') resetFormState!: boolean;
  @apiKeyModule.Getter('getAPIKeys') accessKeys!: IAPIKeys;
  @smsModule.Getter('getSenderIDList') smsIDList!: Array<
    ISenderID & { uuid: string; id: number; slug: string }
  >;
  @authModule.Getter('getUserDetails') user!: IUser;
  @eventsModule.Getter('getEventDetails') eventDetails!: IEventActivity;
  @usersModule.Getter('getAllFavoriteIDs') favoriteIDs!: string[];

  items = [
    {
      src: 'https://cdn.vuetifyjs.com/images/carousel/squirrel.jpg',
    },
    {
      src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',
    },
    {
      src: 'https://cdn.vuetifyjs.com/images/carousel/bird.jpg',
    },
    {
      src: 'https://cdn.vuetifyjs.com/images/carousel/planet.jpg',
    },
  ];

  ticketDetails: ITickets | undefined = {
    name: '',
    description: '',
    type: '',
    status: '',
    posterUrl: '',
    price: 0,
    discountedPrice: 0,
    discount: 0,
    _id: '',
  };

  @Watch('eventDetails')
  onEventDetailsChange(
    event: IEventActivity & {
      receivers: string;
      eventGroupsContacts: Array<{
        id: number;
        uuid: string;
        groupId: IGroup & { groupContactsId: any };
        contactId: IContact;
      }>;
    }
  ): void {
    console.log(event);
  }

  openTicket(id: string): void {
    this.open('add');
    this.ticketDetails = this.eventDetails.tickets.find(
      ({ _id }) => _id === id
    );
  }
  @Watch('$route.params.id', {
    deep: true,
  })
  onRouterChange(eventId: number): void {
    this.$store.dispatch('events/details', { id: eventId });
  }

  addFavorite(id: string | undefined): void {
    this.$store.dispatch('users/addFavorite', { eventId: id });
  }

  buyTicket(payload: any): void {
    this.$store.dispatch('events/buyTicket', {
      ...payload,
      eventId: this.$route.params.id,
    });
  }

  created(): void {
    this.$store.dispatch('auth/me');
    this.$store.dispatch('events/details', { id: this.$route.params.id });
    this.$store.dispatch('users/listFavorites');
  }
}
